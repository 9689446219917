import apiClient from "../apiService";



export const inviteMember = async (organizationId, email, role) => {
    try {
        const response = await apiClient.post(
            `/v1/invites`, 
            { email, role }, 
            {
                headers: {
                    'X-Organisation-Identity': organizationId,
                }
            }
        );
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Server Error');
    }
};


export const acceptInvite = async (inviteCode, organizationId, email) => {
    try {
      const response = await apiClient.put('/v1/invites/accept', 
        {inviteCode,
        email},
        {
          headers: {
            'X-Organisation-Identity': organizationId,
          }
        }
      );
      if (response.status === 204) {
        return; 
      } else {
        throw new Error(`Failed to accept invite: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error accepting invite:', error);
      throw error.response ? error.response.data : new Error('An unexpected error occurred while accepting the invite.');
    }
  };
  
  export const getInvites = async (organizationId) => {
    try {
      const response = await apiClient.get(`/v1/invites`, {
        headers: {
          'X-Organisation-Identity': organizationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching invites:', error.message, error.stack);
      throw error;
    }
  };




export const resendInvite = async (inviteCode) => {
    try {
        const response = await apiClient.post(`/v1/invites/resend`, { inviteCode });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Server Error');
    }
};

export const cancelInvite = async (inviteCode, organizationId) => {
    try {
        const response = await apiClient.delete(`/v1/invites/${inviteCode}`, {
            headers: {
                'X-Organisation-Identity': organizationId
            }
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Server Error');
    }
};

export const declineInvite = async (inviteCode, organizationId, email) => {
  if (!inviteCode || !organizationId) {
    throw new Error('Invite code or organization ID is missing.');
  }
  try {
    const response = await apiClient.delete(`/v1/me/organisation-invites/${inviteCode}`, {
      params: { email }, 
      headers: {
        'X-Organisation-Identity': organizationId
      }
    });
    if (response.status === 204) {
      return; 
    } else {
      throw new Error(`Failed to cancel invite: ${response.statusText}`);
    }
  } catch (error) {
    throw error.response ? error.response.data : new Error('Server Error');
  }
};