import React, { useState, useEffect } from 'react';
import { inviteMember } from 'services/api/inviteService';
import { getOrganisation } from 'services/api/organizationService';
import { FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const InviteMember = ({ onCancel, slug }) => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Member');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [organisationName, setOrganisationName] = useState('');

  useEffect(() => {
    const fetchOrganisationName = async () => {
      try {
        const response = await getOrganisation(slug);
        if (response && response.organisation && response.organisation.name) {
          setOrganisationName(response.organisation.name);
        } else {
          console.error('Organization name not found in response');
        }
      } catch (err) {
        console.error('Error fetching organization:', err);
      }
    };

    fetchOrganisationName();
  }, [slug]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await inviteMember(slug, email, role);
      toast.success('User invited successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
      setEmail('');
      setRole('Member');
      onCancel();
    } catch (err) {
      setMessage(err.message || 'Error inviting user.');
      toast.error(err.message || 'Error inviting user.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 border rounded-lg shadow-lg bg-white dark:bg-navy-700 dark:border-gray-700">
      <div className="pb-4 mb-4 border-gray-200 w-full ">
        <h2 className="text-lg font-bold mb-5 w-full lg:text-xl dark:text-white">
          Invite User
        </h2>
        <div className="flex items-center dark:border-gray-700">
          <div className="flex flex-row items-center">
            <FaUser className="w-4 h-4 text-gray-500 mr-2" />
            <div className="mt-2">
              {organisationName ? (
                <p className="text-gray-900 text-xs font-semibold mb-2 text-center lg:text-md dark:text-white">
                  Invite a new user to{' '}
                  <span className="text-customBlue font-bold dark:text-blue-400">
                    {organisationName}
                  </span>{' '}
                  organization
                </p>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {message && (
          <p
            className={`text-center mb-4 ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}
          >
            {message}
          </p>
        )}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="email"
          >
            Email:
          </label>
          <input
            id="email"
            type="email"
            placeholder="Enter email here"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-navy-700 dark:text-white dark:border-gray-700"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="role"
          >
            Role:
          </label>
          <select
            id="role"
            value={role}
            onChange={e => setRole(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 mb-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-navy-700 dark:text-white dark:border-gray-700"
          >
            <option value="Admin">Admin</option>
            <option value="Member">Member</option>
            <option value="Viewer">Viewer</option>
          </select>
        </div>
        <div className="flex justify-end space-x-4 pt-4 mt-4">
          <button
            type="button"
            onClick={onCancel}
            className="bg-white text-gray-900 text-xs px-4 py-2 rounded-md shadow-md font-semibold hover:bg-gray-800 hover:text-white hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out lg:text-sm dark:bg-navy-600 dark:hover:bg-navy-700 dark:text-white"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className={`flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm dark:bg-navy-600 dark:hover:bg-navy-700 ${
              loading ? 'bg-blue-300' : 'bg-blue-500 hover:bg-blue-700'
            }`}
          >
            {loading ? 'Inviting...' : 'Invite Member'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default InviteMember;
