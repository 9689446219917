const WidgetCard = ({ title, children, className }) => {
  return (
    <section
      className={`w-full max-w-lg bg-white shadow-lg rounded-lg p-4 dark:bg-navy-700 ${className}`}
    >
      <div className="text-sm font-bold text-gray-800  mb-6 dark:text-white">
        {title}
      </div>
      {children}
    </section>
  );
};

export default WidgetCard;
