import React from 'react';
import amazonIcon from '../assets/img/aws.webp';
import googleIcon from '../assets/img/gcp.webp';
import azureIcon from '../assets/img/azure.webp';
import scalewayIcon from '../assets/img/scaleway.svg'

const CloudIcon = ({ cloudProviderType, className }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'aws':
        return amazonIcon;
      case 'gcp':
        return googleIcon;
      case 'azure':
        return azureIcon;
      case 'scaleway':
        return scalewayIcon;
      default:
        return null; 
    }
  };

  return (
    <img
      className={className}
      src={getIcon(cloudProviderType)}
      alt={`${cloudProviderType} icon`}
    />
  );
};

export default CloudIcon;
