import apiClient from "../apiService";



export const getDeployments = async (organisationId, tenantId, namespaceIdentity) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespaceIdentity}/deployments`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting deployments:', err);
        throw err;
    }
};


export const getDeploymentDetails = async (organisationId, tenantId, namespaceIdentity, deploymentName) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespaceIdentity}/deployments/${deploymentName}/details`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        console.log(response.data)
        return response.data;
    } catch (err) {
        console.error('Error getting deployment details:', err);
        throw err;
    }
}


export const getPods = async (organisationId, tenantId, namespaceIdentity) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespaceIdentity}/pods`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting pods:', err);
        throw err;
    }
};


export const getPodDetails = async (organisationId, tenantId, namespaceIdentity, podName) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespaceIdentity}/pods/${podName}/details`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting pod details:', err.response ? err.response.data : err.message);
        throw err;
    }
}


export const getServices = async (organisationId, tenantId, namespaceIdentity) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespaceIdentity}/services`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting services:', err);
        throw err;
    }
};



export const getNamespaceYaml = async (organisationId, tenantId, namespace_identity) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespace_identity}/yaml`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting namespace yaml:', err);
        throw err;
    }
}

export const getPodYaml = async (organisationId, tenantId, namespace_identity, podName, resources) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespace_identity}/pods/${podName}/resources/${resources}/yaml`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting pod yaml:', err);
        throw err;
    }
}

export const exposeApp = async (organisationId, tenantId, namespaceId, serviceName, servicePort) => {
    const payload = {
        serviceName,
        servicePort,
        cloud: "scale",
        location: "fra",
        namespace: "default",
    };
    try {
        const response = await apiClient.post(`/v1/tenants/${tenantId}/namespaces/${namespaceId}/expose`, payload, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        if (err.response) {
            throw new Error(`Error exposing app: ${err.response.data || 'Unknown error'}`);
        }
        throw new Error('Error exposing app: Network or server error');
    }
};