// CreateTeam.js
import React, { useState } from 'react';
import { createTeam } from 'services/api/teamsService';
import { getOrganisationMemberships } from 'services/api/organizationService';
import { toast } from 'react-toastify';

const CreateTeam = ({ slug, onTeamCreated, onCancel }) => {
  const [newTeamName, setNewTeamName] = useState('');
  const [error, setError] = useState(null);

  const handleCreateTeam = async () => {
    if (newTeamName.trim() === '') {
      return;
    }

    try {
      const orgData = await getOrganisationMemberships();
      const userRole = orgData[0].role;

      if (userRole !== 'OWNER' && userRole !== 'ADMIN') {
        toast.error('You are not able to create a team.', {
          autoClose: 2000,
          position: 'top-center',
        });
        setError('');
        return;
      }

      const createdTeamResponse = await createTeam({ name: newTeamName }, slug);
      const createdTeam = createdTeamResponse.data;
      onTeamCreated(createdTeam);
      setNewTeamName('');
      toast.success('Team created successfully.', {
        autoClose: 2000,
        position: 'top-center',
      });
      setError(null);
    } catch (error) {
      console.error('Error creating team:', error);
      toast.error('Failed to create team.', {
        autoClose: 2000,
        position: 'top-center',
      });
      setError('Failed to create team.');
    }
  };

  return (
    <div className="p-4 ">
      <h3 className="text-sm font-bold mb-4 lg:text-lg dark:text-white">
        Create New Team
      </h3>
      {error && <p className="">{error}</p>}
      <input
        type="text"
        placeholder="Team Name"
        className="border border-gray-300 p-2 w-full mb-4 dark:bg-navy-700 dark:text-white"
        value={newTeamName}
        onChange={e => setNewTeamName(e.target.value)}
      />
      <div className="flex gap-2 justify-end">
        <button
          onClick={handleCreateTeam}
          className="bg-customBlue hover:bg-gray-800 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm dark:bg-navy-600 dark:text-white  dark:hover:bg-navy-700"
        >
          Create team
        </button>
        <button
          onClick={onCancel}
          className="bg-white hover:bg-gray-800 hover:text-white shadow-md text-xs text-gray-900 px-4 py-2 font-semibold rounded-md lg:text-sm dark:bg-navy-600 dark:text-white dark:hover:bg-navy-700"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateTeam;
