const FreeCard = () => {
  return (
    <div className="relative mt-14 flex w-[256px] justify-center rounded-[20px] bg-gradient-to-br from-[#1351A4] via-[#1351A4] to-brand-700 pb-4">
      <div className="absolute -top-12 flex h-24 w-24 items-center justify-center rounded-full border-[4px] border-white bg-gradient-to-b from-[#1351A4] to-brand-300 dark:!border-navy-800">
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            points="100,20 170,50 170,150 100,180 30,150 30,50"
            fill="none"
            stroke="white"
            strokeWidth="4"
          />

          <path
            d="M100 60 L140 80 L140 120 L100 100 Z"
            fill="none"
            stroke="white"
            strokeWidth="4"
          />
          <path
            d="M100 60 L60 80 L60 120 L100 100 Z"
            fill="none"
            stroke="white"
            strokeWidth="4"
          />
          <path
            d="M140 80 L60 80 L100 100 L140 100 Z"
            fill="none"
            stroke="white"
            strokeWidth="4"
          />
        </svg>
      </div>

      <div className="mt-16 flex h-fit flex-col items-center">
        <p className="text-lg font-bold text-white">Namespaces for Everyone</p>
        <p className="mt-1 px-4 text-center text-sm text-white">
          Unleash developer productivity
          <br /> without the hassle for maintaining infrastructure.
        </p>

        <a
          target="blank"
          className="text-medium mt-7 block rounded-full bg-gradient-to-b from-white/50 to-white/10 py-[12px] px-11 text-center text-base text-white hover:bg-gradient-to-b hover:from-white/40 hover:to-white/5 "
          href="https://docs.kubespaces.io/docs/intro"
        >
          Docs
        </a>
      </div>
    </div>
  );
};

export default FreeCard;
