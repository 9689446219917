import React, { useState } from 'react';
import KeycloakService from 'services/KeycloakService';

const Security = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const updatePassword = async () => {
    try {
      await KeycloakService.updatePassword(currentPassword, newPassword);
      console.log('Password updated successfully');
      setCurrentPassword('');
      setNewPassword('');
    } catch (error) {
      console.error('Failed to update password:', error);
    }
  };

  return (
    <div className="bg-white dark:bg-navy-700 shadow-md rounded-lg p-6">
      <div className="flex items-center justify-between border-b pb-3 mb-4 dark:border-gray-700">
        <h3 className="text-md font-semibold text-gray-700 dark:text-white">
          Security
        </h3>
        <button
          className="text-sm text-blue-400 hover:underline"
          onClick={updatePassword}
        >
          Update Password
        </button>
      </div>
      <div className="space-y-4">
        <div className="flex gap-6 items-center">
          <p className="text-sm text-gray-700 whitespace-nowrap dark:text-gray-400 w-24">
            Current Password:
          </p>
          <p className="dark:text-white">***********</p>
          {/* <input
            type="password"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
            className="border rounded px-2 py-1 dark:bg-navy-700 dark:text-white"
            autoComplete="off"
          /> */}
        </div>
        <div className="flex items-center">
          <p className="text-sm text-gray-700 dark:text-gray-400 w-24">
            New Password:
          </p>
          <p></p>
          {/* <input
            type="password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            className="border rounded px-2 py-1 dark:bg-navy-700 dark:text-white"
            autoComplete="off"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Security;
