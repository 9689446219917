import React from 'react';
import Loading from 'views/loading/Loading';
import { FaBackward } from 'react-icons/fa';
import GetYaml from './GetYaml';
import moment from 'moment';

const PodDetails = ({
  organizationId,
  tenantId,
  namespaceId,
  podDetails,
  onBack,
}) => {
  if (!podDetails) return <Loading />;

  const { metadata, spec, status } = podDetails;

  const creationTimestamp = metadata?.creationTimestamp;
  const podLifetime = creationTimestamp
    ? moment(creationTimestamp).fromNow()
    : 'N/A';

  return (
    <div>
      <button
        onClick={onBack}
        className="flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:mb-7 dark:bg-navy-600 dark:hover:bg-navy-700"
      >
        <FaBackward className="mr-2" />
        Back to Resources
      </button>
      <h2 className="text-sm mt-6 font-bold mb-4 lg:text-lg lg:mt-0 dark:text-white">
        Pod Details
      </h2>

      {/* Metadata section */}
      <div className="mb-4">
        <h3 className="text-xs font-semibold lg:text-sm dark:text-white">
          Metadata
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 mt-4 dark:divide-gray-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-white">
                  Key
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-white">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              <tr>
                <td className="px-4 py-2 text-xs whitespace-nowrap dark:text-white">
                  Pod Name
                </td>
                <td className="px-8 py-2 text-xs dark:text-white">
                  {metadata?.name}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 whitespace-nowrap text-xs dark:text-white">
                  Namespace
                </td>
                <td className="px-8 py-2 text-xs dark:text-white">
                  {metadata?.namespace}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 whitespace-nowrap text-xs dark:text-white">
                  Labels
                </td>
                <td className="px-8 py-2 text-xs dark:text-white">
                  <ul>
                    {metadata?.labels &&
                      Object.entries(metadata.labels).map(([key, value]) => (
                        <li key={key}>
                          {key}: {value}
                        </li>
                      ))}
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 whitespace-nowrap text-xs dark:text-white">
                  Annotations
                </td>
                <td className="px-8 py-2 text-xs dark:text-white">
                  <ul>
                    {metadata?.annotations &&
                    Object.entries(metadata.annotations).length > 0 ? (
                      Object.entries(metadata.annotations).map(
                        ([key, value]) => (
                          <li key={key}>
                            {key}: {value}
                          </li>
                        ),
                      )
                    ) : (
                      <li>No annotations available.</li>
                    )}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Spec section */}
      <div className="mb-4">
        <h3 className="text-xs font-semibold lg:text-sm dark:text-white">
          Spec
        </h3>
        <div className="mt-4">
          {spec?.containers?.map((container, index) => (
            <div
              key={index}
              className=" bg-white dark:bg-navy-700 border-gray-300 dark:border-gray-600"
            >
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 mt-4 dark:divide-gray-700">
                  <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Container:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {container.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Image:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {container.image}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Ports:
                      </td>
                      <td className="px-2 py-2 text-xs dark:text-white">
                        {container.ports?.length > 0 ? (
                          <ul className="list-disc list-inside pl-4">
                            {container.ports.map((port, index) => (
                              <li key={index}>
                                {port.containerPort}/{port.protocol}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Resources:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {container & container.resources ? (
                          <ul className="list-disc list-inside pl-4">
                            <li>
                              CPU: {container.resources.requests.cpu || 'N/A'}
                            </li>
                            <li>
                              Memory:{' '}
                              {container.resources.requests.memory || 'N/A'}
                            </li>
                          </ul>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Volume Mounts:
                      </td>
                      <td className="px-2 py-2 text-xs dark:text-white">
                        {container.volumeMounts?.length > 0 ? (
                          <ul className="list-disc list-inside pl-4">
                            {container.volumeMounts.map((mount, index) => (
                              <li key={index}>
                                {mount.name}: {mount.mountPath}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Node Name:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {spec.nodeName}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Priority:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {spec.priority}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Scheduler Name:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {spec.schedulerName}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Service Account Name:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {spec.serviceAccountName}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-medium text-xs dark:text-white">
                        Restart Policy:
                      </td>
                      <td className="px-4 py-2 text-xs dark:text-white">
                        {spec.restartPolicy}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-xs font-semibold lg:text-sm dark:text-white">
          Volumes
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 mt-4 dark:divide-gray-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-white">
                  Volume Name
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-white">
                  Type
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              {spec?.volumes &&
                spec.volumes.map((volume, index) => (
                  <tr
                    key={index}
                    className="transition hover:bg-gray-50 dark:hover:bg-navy-600"
                  >
                    <td className="px-4 py-2 text-xs dark:text-white">
                      {volume.name}
                    </td>
                    <td className="px-4 py-2 text-xs dark:text-white">
                      {volume.secret ? 'Secret' : 'Other'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Status section */}
      <div className="mb-4">
        <h3 className="text-xs font-semibold lg:text-sm dark:text-white">
          Status
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 mt-4 dark:divide-gray-700">
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              <tr>
                <td className="px-4 py-2 font-medium text-xs dark:text-white">
                  Phase
                </td>
                <td className="px-4 py-2 text-xs dark:text-white">
                  {status?.phase}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium text-xs dark:text-white">
                  Pod IP
                </td>
                <td className="px-4 py-2 text-xs dark:text-white">
                  {status?.podIP}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium text-xs dark:text-white">
                  Host IP
                </td>
                <td className="px-4 py-2 text-xs dark:text-white">
                  {status?.hostIP}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium text-xs dark:text-white">
                  Pod Lifetime
                </td>
                <td className="px-4 py-2 text-xs dark:text-white">
                  {podLifetime}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium text-xs dark:text-white">
                  Conditions
                </td>
                <td className="px-4 py-2 text-xs dark:text-white">
                  <ul>
                    {status?.conditions &&
                      status.conditions.map((condition, index) => (
                        <li key={index}>
                          {condition.type}: {condition.status}
                        </li>
                      ))}
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium text-xs dark:text-white">
                  Container Statuses
                </td>
                <td className="px-4 py-2 text-xs dark:text-white">
                  <ul>
                    {status?.containerStatuses &&
                      status.containerStatuses.map((container, index) => (
                        <li key={index}>
                          <strong>{container.name}</strong>:{' '}
                          {container.state.running
                            ? 'Running'
                            : container.state.waiting
                              ? `Waiting (${container.state.waiting.reason})`
                              : container.state.terminated
                                ? `Terminated (${container.state.terminated.reason})`
                                : 'Unknown'}{' '}
                          - Ready: {container.ready ? 'Yes' : 'No'} - Restarts:{' '}
                          {container.restartCount}
                        </li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Yaml section */}
      <div className="mb-2">
        <h3 className="text-xs font-semibold lg:text-sm dark:text-white">
          YAML
        </h3>
        <GetYaml
          organizationId={organizationId}
          tenantId={tenantId}
          namespaceId={namespaceId}
          pod={podDetails}
          resourceType="pod"
          resourceName={metadata?.name}
        />
      </div>
    </div>
  );
};

export default PodDetails;
