import axios from 'axios';
import KeycloakService from '../services/KeycloakService'; 

const API_BASE_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token in all requests
apiClient.interceptors.request.use(
  async config => {
    try {
      const token = KeycloakService.getToken();  

      if (!token) {
        throw new Error('No authentication token found.');
      }

      config.headers.Authorization = `Bearer ${token}`; 
    } catch (error) {
      console.error('Error getting authentication token:', error);
      return Promise.reject(error);
    }

    return config;
  },
  error => {
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  }
);




export default apiClient;
