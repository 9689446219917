import React, { useState, useEffect } from 'react';

const Profile = ({
  displayMember,
  name = 'No Name Provided',
  email = 'No Email Provided',
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedEmail, setEditedEmail] = useState('');

  useEffect(() => {
    setEditedName(displayMember?.user?.displayName || name);
    setEditedEmail(displayMember?.user?.email || email);
  }, [displayMember, name, email]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    localStorage.setItem('name', editedName);
    localStorage.setItem('email', editedEmail);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedName(displayMember?.user?.displayName || name);
    setEditedEmail(displayMember?.user?.email || email);
    setIsEditing(false);
  };

  return (
    <div className="bg-white dark:bg-navy-700 shadow-md rounded-lg p-6">
      <div className="flex items-center justify-between border-b pb-3 mb-4 dark:border-gray-700">
        <h3 className="text-md font-semibold text-gray-700 dark:text-white">
          Profile
        </h3>
        {isEditing ? (
          <div className="flex gap-2">
            <button
              className="text-sm text-blue-400 hover:underline"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              className="text-sm text-red-400 hover:underline"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            className="text-sm text-blue-400 hover:underline"
            onClick={handleEditClick}
          >
            Edit
          </button>
        )}
      </div>
      <div className="space-y-4">
        <div className="flex items-center">
          <p className="text-sm text-gray-700 dark:text-gray-400 w-24">Name:</p>
          {isEditing ? (
            <input
              type="text"
              value={editedName}
              onChange={e => setEditedName(e.target.value)}
              className="border rounded px-2 py-1 dark:bg-navy-700 dark:text-white"
            />
          ) : (
            <p className="text-sm text-gray-900 dark:text-white">
              {editedName}
            </p>
          )}
        </div>
        <div className="flex items-center">
          <p className="text-sm text-gray-700 dark:text-gray-400 w-24">
            Email:
          </p>
          {isEditing ? (
            <input
              type="email"
              value={editedEmail}
              onChange={e => setEditedEmail(e.target.value)}
              className="border rounded px-2 py-1 dark:bg-navy-700 dark:text-white"
            />
          ) : (
            <p className="text-sm text-gray-900 dark:text-white">
              {editedEmail}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
