import React, { useState, useEffect } from 'react';
import { getOrganisationMemberships } from 'services/api/organizationService';
import Widget from 'components/widget/Widget';
import { FaBuilding, FaUserTie, FaTag } from 'react-icons/fa';

export default function OrganizationWidget({ setOrganizationId }) {
  const [organization, setOrganization] = useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [role, setRole] = useState('');
  const [orgSlug, setOrgSlug] = useState('');

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const response = await getOrganisationMemberships();
        const organisationData = response[0];
        setOrganization(organisationData);

        const organisationName = organisationData.organisation.name;
        const role = organisationData.role;
        const orgSlug = organisationData.organisation.slug;
        const organizationId = organisationData.organisation.identity;

        if (typeof setOrganizationId === 'function') {
          setOrganizationId(organizationId);
        }

        setOrganizationName(organisationName);
        setRole(role);
        setOrgSlug(orgSlug);
      } catch (err) {
        console.error('Failed to fetch memberships.');
        console.error(err);
      }
    };

    fetchOrganisationData();
  }, [setOrganizationId]);

  return (
    <div className="max-w-8xl mx-auto mt-14">
      {organization && (
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          <Widget
            icon={<FaBuilding className="h-7 w-7" />}
            title="Organization"
            subtitle={organizationName || 'N/A'}
          />
          <Widget
            icon={<FaUserTie className="h-6 w-6" />}
            title="Role"
            subtitle={role || 'N/A'}
          />
          <Widget
            icon={<FaTag className="h-7 w-7" />}
            title="OrgID"
            subtitle={orgSlug || 'N/A'}
          />
        </div>
      )}
    </div>
  );
}
