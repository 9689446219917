import React  from "react";

// Admin Imports
import Overview from "views/client/overview";
import Organisation from "views/client/organisation";
import Tenants from "views/client/tenants";
import Namespaces from "views/client/namespaces";
import Members from "views/client/members";
//import RTLDefault from "views/rtl/default";
//import HostClusterList from "views/admin/host-cluster/HostClusterList";
// Auth Imports
import Settings from "views/auth/Settings";

// Icon Imports
import {
  MdHome,
  MdOutlinePeopleOutline,
  MdBackup,
  MdSpaceDashboard,
  MdApartment,
  MdSettings,
} from "react-icons/md";

const routes = [
  {
    name: "Overview",
    layout: "",
    path: "",
    icon: <MdHome className="h-6 w-6" />,
    component: <Overview />,
  },
  {
    name: "Organization",
    layout: "/org",
    path: "organization",
    icon: <MdApartment className="h-6 w-6" />,
    component: <Organisation />,
  },
  {
    name: "Members",
    layout: "/org",
    path: "organization-members",
    icon: <MdOutlinePeopleOutline className="h-6 w-6" />,
    component: <Members />,
    secondary: true,
  },


  {
    name: "Tenants",
    layout: "/org",
    icon: <MdBackup className="h-6 w-6" />,
    path: "tenants",
    component: <Tenants />,
  },

  {
    name: "Namespaces",
    layout: "/org",
    path: "namespaces",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <Namespaces />,
  },
  {
    name: "Settings",
    layout: "/auth",
    path: "settings",
    icon: <MdSettings className="h-6 w-6" />,
    component: <Settings />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
  // {
  //   name: "Clusters/Regions",
  //   layout: "/admin",
  //   path: "create-host-cluster",
  //   icon: <MdCloudCircle className="h-6 w-6" />,
  //   component: <HostClusterList />,
  // }
];
export default routes;
