import React, { useState, useEffect } from 'react';
import { formatDateTime } from 'utilities/formatDateTime';
import { getTeam } from 'services/api/teamsService';
import { FaRegCalendarAlt, FaUser } from 'react-icons/fa';
import Loading from 'views/loading/Loading';

const GetTeam = ({ teamId, organisationId }) => {
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const data = await getTeam(teamId, organisationId);
        setTeam(data);
      } catch (error) {
        setError('Failed to load team details.');
        console.error('Error fetching team details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeam();
  }, [teamId, organisationId]);

  if (loading) return <Loading />;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!team) return <p>No team found.</p>;

  return (
    <div className="mb-8 lg:mb-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center cursor-pointer border-b pb-4 mb-4">
        <h2 className="text-sm font-bold mb-2 sm:mb-0 lg:text-lg dark:text-white">
          {team.data.name}'s team
        </h2>
      </div>

      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-8">
        <div className="flex items-center">
          <FaUser className="mr-2 text-gray-500" />
          <span className="text-sm text-gray-700">
            ID: {team.data.identity}
          </span>
        </div>
        <div className="flex items-center">
          <FaRegCalendarAlt className="mr-2 text-gray-500" />
          <span className="text-sm text-gray-700">
            Created At: {formatDateTime(team.data.createdAt)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GetTeam;
