import React from 'react';
import Dropdown from 'components/dropdown';
import KubeImage from 'assets/img/info.png';
import { IoMdInformationCircleOutline } from 'react-icons/io';

const InfoDropdown = () => {
  return (
    <Dropdown
      button={
        <p className="cursor-pointer">
          <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
        </p>
      }
      children={
        <div className="flex w-[240px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 md:w-[300px] lg:w-[350px] dark:!bg-navy-700 dark:text-white dark:shadow-none">
          <div
            style={{
              backgroundImage: `url(${KubeImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            className="mb-2 aspect-video w-full rounded-lg"
          />
          <a
            target="blank"
            href="https://kubespaces.io/pricing"
            className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-customBlue py-[11px] font-bold text-white transition duration-200 hover:bg-gray-900 hover:text-white active:bg-brand-700 dark:bg-navy-600 dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-brand-200"
          >
            Prices
          </a>
          <a
            target="blank"
            href="https://kubespaces.io/"
            className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
          >
            Blog
          </a>
          <a
            target="blank"
            href="https://kubespaces.io/features"
            className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
          >
            Features
          </a>
        </div>
      }
      classNames={'py-2 top-6 right-0 -start-[250px] md:-start-[330px] w-max '}
      animation="origin-[75%_0%] md:origin-top-end transition-all duration-300 ease-in-out"
    />
  );
};

export default InfoDropdown;
