import React, { useState } from 'react';
import Pods from './components/pods/Pods';
import Deployments from './components/deployments/Deployments';
import Services from './components/Services';
import PodDetails from './components/pods/PodDetails';

const Resources = ({ organizationId, tenantId, namespaceId }) => {
  const [selectedPodDetails, setSelectedPodDetails] = useState(null);

  return (
    <div className="resources-container">
      {selectedPodDetails ? (
        <PodDetails
          podDetails={selectedPodDetails}
          organizationId={organizationId}
          tenantId={tenantId}
          namespaceId={namespaceId}
          onBack={() => setSelectedPodDetails(null)}
        />
      ) : (
        <>
          <section className="pods-section">
            <Pods
              organizationId={organizationId}
              tenantId={tenantId}
              namespaceId={namespaceId}
              onPodSelect={setSelectedPodDetails}
            />
          </section>

          <section className="deployments-section">
            <Deployments
              organizationId={organizationId}
              tenantId={tenantId}
              namespaceId={namespaceId}
            />
          </section>

          <section className="services-section">
            <Services
              organizationId={organizationId}
              tenantId={tenantId}
              namespaceId={namespaceId}
            />
          </section>
        </>
      )}
    </div>
  );
};

export default Resources;
