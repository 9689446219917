import Keycloak from 'keycloak-js';


// Define Keycloak configuration object
const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

const keycloak = new Keycloak(keycloakConfig);

const fetchGithubAvatarUrl = async (username) => {
  try {
    let githubUsername = username;

    if (username.includes('@')) {
      const response = await fetch(`https://api.github.com/search/users?q=${username}`);
      const data = await response.json();
      if (data.items && data.items.length > 0) {
        githubUsername = data.items[0].login; 
      } else {
        return null; 
      }
    }

    const response = await fetch(`https://api.github.com/users/${githubUsername}`);
    if (!response.ok) {
      console.error("Failed to fetch GitHub user:", response.status);
      return null; 
    }
    
    const data = await response.json();
    return data.avatar_url;
  } catch (error) {
    console.error("Failed to fetch GitHub avatar URL:", error);
    return null;
  }
};


const initKeycloak = async (onAuthenticatedCallback) => {
  try {
    const authenticated = await keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      checkLoginIframe: false, 
    });


    if (authenticated) {

      const githubUsername = keycloak.tokenParsed.preferred_username;
      const githubAvatarUrl = await fetchGithubAvatarUrl(githubUsername);
      const microsoftProfilePhotoUrl = keycloak.tokenParsed.microsoft_avatar_url; 
      const googleProfilePhotoUrl = keycloak.tokenParsed.picture; 

      // Update user profile with the avatars
      await KeycloakService.updateUserProfile(
        keycloak.tokenParsed.sub,
        githubAvatarUrl,
        googleProfilePhotoUrl,
        microsoftProfilePhotoUrl
      );
    }
    
    onAuthenticatedCallback(authenticated);
  } catch (error) {
    console.error("Keycloak initialization failed:", error);
    onAuthenticatedCallback(false);
  }
};

const KeycloakService = {
  keycloak,
  initKeycloak,
  doLogin: () => keycloak.login(),
  doLogout: () => keycloak.logout(),
  isLoggedIn: () => !!keycloak.token,
  getToken: () => keycloak.token,
  updateToken: async (minValidity = 5) => {
    try {
      const refreshed = await keycloak.updateToken(minValidity);
      if (refreshed) {
        console.log('Token refreshed');
      } else {
        console.log('Token is still valid');
      }
      return keycloak.token;
    } catch (error) {
      console.error('Failed to refresh token', error);
      throw error;
    }
  },
  getUsername: () => keycloak.tokenParsed?.preferred_username,
  hasRole: (roles) => roles.some(role => keycloak.hasRealmRole(role)),
  async getUserProfile() {
    try {
      const userProfile = await keycloak.loadUserProfile();
      return {
        githubAvatarUrl: userProfile.attributes?.github_avatar_url,
        googleProfilePhotoUrl: userProfile.attributes?.picture, 
        microsoftProfilePhotoUrl: userProfile.attributes?.microsoft_avatar_url
      };
    } catch (error) {
      console.error("Failed to load user profile:", error);
      return {
        githubAvatarUrl: null,
        googleProfilePhotoUrl: null,
        microsoftProfilePhotoUrl: null
      };
    }
  },
  async updateUserProfile(userId, githubAvatarUrl, googleProfilePhotoUrl, microsoftProfilePhotoUrl) {
    const userProfile = await keycloak.loadUserProfile();
    const attributes = userProfile.attributes || {};
    
    if (githubAvatarUrl) {
      attributes.github_avatar_url = githubAvatarUrl;
    }
    if (googleProfilePhotoUrl) {
      attributes.picture = googleProfilePhotoUrl; 
    }
    if (microsoftProfilePhotoUrl) {
      attributes.microsoft_avatar_url = microsoftProfilePhotoUrl; 
    }
    
    await keycloak.updateUserProfile({ attributes });
  },
  
};

export default KeycloakService;
