import React, { useState, useEffect, useRef } from 'react';
import { FaEllipsisV } from 'react-icons/fa';

const NamespaceSummary = ({ namespace }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const menuRef = useRef(null);

  const toggleMenu = namespaceId => {
    setOpenMenu(openMenu === namespaceId ? null : namespaceId);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!namespace) {
    return <p>No namespace data available.</p>;
  }

  return (
    <div className="max-w-8xl mx-auto mt-3">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-400 dark:bg-navy-700">
            <tr>
              <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase">
                Namespace Name
              </th>
              <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase">
                Description
              </th>
              <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase"></th>
            </tr>
          </thead>
          <tbody className="bg-white hover:bg-gray-50 dark:bg-navy-700 dark:hover:bg-navy-600">
            <tr>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                {namespace.name}
              </td>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                {namespace.description}
              </td>
              <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                <button
                  onClick={() => toggleMenu(namespace.identity)}
                  className="text-gray-500 hover:text-gray-700 ml-2"
                >
                  <FaEllipsisV />
                </button>
                {openMenu === namespace.identity && (
                  <div
                    ref={menuRef}
                    className="absolute right-4 mt-2 w-48 bg-white shadow-lg rounded-lg dark:bg-navy-700"
                  >
                    <ul className="py-1">
                      <li>
                        <a
                          href="#edit"
                          className="block px-6 py-2 text-sm text-gray-700 hover:bg-gray-50 dark:hover:bg-navy-600 dark:text-gray-300"
                        >
                          Edit
                        </a>
                      </li>
                      <li className="border-t border-gray-200">
                        <a
                          href="#leave"
                          className="block px-6 py-2 text-sm text-red-600 hover:bg-gray-50 dark:text-red-500 dark:hover:bg-navy-600"
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NamespaceSummary;
