import React, { useState, useEffect } from 'react';
import OrganizationCard from './components/OrganizationCard';
import TenantCard from './components/TenantCard';
import MemberCard from './components/MemberCard';
import TeamCard from './components/TeamCard';
import WelcomeModal from './components/WelcomeModal';
import KeycloakService from 'services/KeycloakService';

const Overview = () => {
  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState('User  ');

  useEffect(() => {
    const checkLoginStatus = () => {
      if (KeycloakService.isLoggedIn()) {
        const tokenParsed = KeycloakService.keycloak.tokenParsed;
        setUserName(tokenParsed.name || 'User  ');

        setShowModal(true);
      }
    };

    checkLoginStatus();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.setItem('welcomeModalDismissed', 'true');
  };

  return (
    <div className="max-w-8xl mx-auto mt-9">
      {showModal && (
        <WelcomeModal userName={userName} onClose={handleCloseModal} />
      )}

      {/* Dashboard Grid */}
      <section className="w-full max-w-full mt-6">
        <h2 className="text-sm font-bold text-gray-700 border-b pb-4 mb-6 dark:text-white dark:border-gray-700">
          Welcome,{' '}
          <span className="text-customBlue dark:text-blue-400">{userName}</span>
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="grid grid-cols-1 gap-4">
            <OrganizationCard />
          </div>
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            <TenantCard />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <MemberCard />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <TeamCard />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Overview;
