import React, { useState } from 'react';
import { createNamespace } from 'services/api/namespaceService';
import { toast } from 'react-toastify';

const CreateNamespaceModal = ({
  isOpen,
  onClose,
  organizationId,
  tenantId,
  onCreate,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateName = name => {
    const regex = /^[a-z0-9][a-z0-9-]{2,48}[a-z0-9]$/;
    return regex.test(name);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    setFormError(null);

    if (!validateName(name)) {
      setFormError(
        'Namespace name must be between 4-50 characters, start and end with alphanumeric characters, and only contain lowercase alphanumeric characters or "-".',
      );
      setLoading(false);
      return;
    }

    try {
      const namespaceData = { name, description };

      const response = await createNamespace(
        tenantId,
        organizationId,
        namespaceData,
      );
      setSuccess('');
      toast.success('Namespace created successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
      setName('');
      setDescription('');
      setTimeout(() => {
        onCreate(response.data);
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error creating namespace:', error);
      if (error.response && error.response.status === 404) {
        setError('Tenant not found. Please ensure the selected tenant exists.');
        toast.error(
          'Tenant not found. Please ensure the selected tenant exists.',
          {
            autoClose: 3000,
            position: 'top-center',
          },
        );
      } else {
        setError(`Failed to create namespace: ${error.message}`);
        toast.error('Failed to create namespace.', {
          autoClose: 3000,
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 dark:bg-navy-700 dark:bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full lg:max-w-lg dark:bg-navy-700 ">
        <h1 className="text-md font-bold mb-4 text-center lg:text-xl dark:text-white">
          Create Namespace
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-900 mb-2">
              Namespace Name
            </label>
            <input
              type="text"
              placeholder="my-namespace"
              value={name}
              onChange={e => setName(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:text-white"
            />
            <div className="mt-2 text-xs text-gray-900 lg:text-sm dark:text-white">
              <p>
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must have characters length between 4-50
                <br />
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must begin and end with an alphanumeric character [a-z, 0-9]
                <br />
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must contain only lowercase characters.
                <br />
                {validateName(name) ? (
                  <span className="text-green-600 mr-2">✓</span>
                ) : (
                  <span className="text-red-600 mr-2">x</span>
                )}
                Must contain only alphanumeric characters [a-z, 0-9] or '-'
              </p>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900 mb-2">
              Description (Optional)
            </label>
            <textarea
              value={description}
              onChange={e => setDescription(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:bg-navy-700 dark:text-white"
              placeholder="A brief description of the namespace..."
              style={{ resize: 'none' }}
            />
          </div>

          {formError && <p className="text-red-600 text-sm">{formError}</p>}
          {error && <p className="text-red-600 text-sm">{error}</p>}
          {success && <p className="">{success}</p>}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-900 bg-white hover:bg-gray-800 hover:text-white text-xs font-semibold  shadow-md rounded-md lg:text-sm dark:bg-navy-600 dark:text-white dark:hover:bg-navy-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-customBlue text-white text-xs rounded-md hover:bg-gray-800 font-semibold lg:text-sm dark:bg-navy-600 dark:hover:bg-navy-700 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create Namespace'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNamespaceModal;
