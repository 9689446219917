import { useState, useCallback, useEffect } from 'react';
import {
  FaEllipsisV,
  FaArchive,
  FaCheck,
  FaBellSlash,
  FaRegNewspaper,
  FaExclamationTriangle,
  FaUser,
} from 'react-icons/fa';
import { IoMdNotificationsOutline } from 'react-icons/io';
import Dropdown from 'components/dropdown';

function Notifications() {
  const [activeTab, setActiveTab] = useState('messages');
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

  // Dummy data for messages and archived notifications
  const messages = [
    {
      id: 1,
      name: 'Alice',
      time: '2 Hr',
      content: 'Changed an issue from "In progress" to review.',
    },
    {
      id: 2,
      name: 'Bob',
      time: '3 Hr',
      content: 'Added a comment on your task.',
    },
  ];

  const archived = [
    {
      id: 1,
      name: 'Charlie',
      time: '1 Dy',
      content: 'Accepted your invitation to join Kube.',
    },
    { id: 2, name: 'Dave', time: '2 Dy', content: 'Archived a document.' },
  ];

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  const handleNotificationButtonClick = () => {
    setIsNotificationMenuOpen(prev => !prev);
    setIsSettingsMenuOpen(false); // Close settings menu when notifications menu is toggled
  };

  const handleSettingsButtonClick = e => {
    e.stopPropagation(); // Prevent closing the notification dropdown
    setIsSettingsMenuOpen(prev => !prev);
  };

  const handleOutsideClick = useCallback(
    e => {
      if (isSettingsMenuOpen && !e.target.closest('.settings-menu')) {
        setIsSettingsMenuOpen(false);
      }
    },
    [isSettingsMenuOpen],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <Dropdown
      button={
        <IoMdNotificationsOutline
          className="h-4 w-4 text-gray-600 cursor-pointer dark:text-white"
          onClick={handleNotificationButtonClick}
        />
      }
      classNames={
        'absolute right-0 mt-6 w-[16.4rem] h-[25rem] bg-white  rounded-2xl shadow-lg z-40 lg:w-[23rem] dark:bg-navy-700'
      }
      isOpen={isNotificationMenuOpen}
      onClose={() => setIsNotificationMenuOpen(false)}
    >
      {/* Dropdown content */}
      <div className="relative">
        {/* Header */}
        <div className="flex justify-between border-b border-gray-200 p-2">
          <Dropdown
            button={
              <FaEllipsisV
                className="text-blue-900 mt-2 p-2 rounded-full bg-blue-100 cursor-pointer dark:bg-navy-600 dark:text-white"
                onClick={handleSettingsButtonClick}
              />
            }
            classNames={
              'absolute right-2 top-14 w-52 bg-white border border-gray-100 rounded-lg shadow-lg z-50 settings-menu dark:bg-navy-700 dark:border-gray-700'
            }
            onClose={() => setIsSettingsMenuOpen(false)}
          >
            {isSettingsMenuOpen && (
              <div className="px-3 py-2">
                <h2 className="ml-4 text-gray-500 text-sm font-semibold mt-3 mb-2">
                  Settings
                </h2>
                <div className="flex items-center p-2 hover:bg-gray-100 rounded cursor-pointer">
                  <FaArchive className="w-3 h-3 mr-2 text-gray-400" />
                  <p className="text-gray-500 text-sm">Archive All</p>
                </div>
                <div className="flex items-center p-2 hover:bg-gray-100 rounded cursor-pointer">
                  <FaCheck className="w-3 h-3 mr-2 text-gray-400" />
                  <p className="text-gray-500 text-sm">Mark All as Read</p>
                </div>
                <div className="flex items-center p-2 hover:bg-gray-100 rounded cursor-pointer">
                  <FaBellSlash className="w-3 h-3 mr-2 text-gray-400" />
                  <p className="text-gray-500 text-sm">Disable Notifications</p>
                </div>
                <div className="flex items-center p-2 hover:bg-gray-100 rounded border-b border-gray-300 cursor-pointer">
                  <FaRegNewspaper className="w-3 h-3 mr-2 text-gray-400" />
                  <p className="text-gray-500 text-sm">What's New?</p>
                </div>
                <h2 className="ml-4 text-gray-500 text-sm font-semibold mt-3">
                  Feedback
                </h2>
                <div className="flex items-center p-2 hover:bg-gray-100 rounded cursor-pointer">
                  <FaExclamationTriangle className="w-3 h-3 mr-2 text-gray-400" />
                  <p className="text-gray-500 text-sm">Report</p>
                </div>
              </div>
            )}
          </Dropdown>
        </div>

        {/* Notifications Tabs */}
        <div className="px-2 py-4">
          <div className="flex items-center">
            <div
              className={`flex-1 text-center text-gray-500 text-sm font-semibold hover:text-blue-500 p-2 cursor-pointer ${activeTab === 'messages' ? 'border-b-2 border-blue-500 ' : ''}`}
              onClick={() => handleTabClick('messages')}
            >
              <span className={activeTab === 'messages' ? 'text-blue-500' : ''}>
                Messages
              </span>
            </div>
            <div
              className={`flex-1 text-center text-gray-500 text-sm font-semibold hover:text-blue-500 p-2 cursor-pointer ${activeTab === 'archived' ? 'border-b-2 border-blue-500 ' : ''}`}
              onClick={() => handleTabClick('archived')}
            >
              <span className={activeTab === 'archived' ? 'text-blue-500' : ''}>
                Archived
              </span>
            </div>
          </div>
        </div>

        {/* Content based on active tab */}
        <div className="px-3 py-2 overflow-y-auto h-[calc(100%-8rem)]">
          {activeTab === 'messages' &&
            messages.map(msg => (
              <div
                key={msg.id}
                className="flex items-start p-3 hover:bg-gray-100 rounded dark:hover:bg-navy-600"
              >
                <FaUser className="w-5 h-5 mr-3 text-gray-400" />
                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <p className="text-gray-800 font-semibold dark:text-white">
                      {msg.name}
                    </p>
                    <p className="text-gray-500 text-xs dark:text-white">
                      {msg.time}
                    </p>
                  </div>
                  <p className="text-gray-600 text-xs mt-1">{msg.content}</p>
                </div>
              </div>
            ))}
          {activeTab === 'archived' &&
            archived.map(item => (
              <div
                key={item.id}
                className="flex items-start p-3 hover:bg-gray-100 rounded"
              >
                <FaUser className="w-5 h-5 mr-3 text-gray-400" />
                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <p className="text-gray-800 font-semibold dark:text-white">
                      {item.name}
                    </p>
                    <p className="text-gray-500 text-xs dark:text-white">
                      {item.time}
                    </p>
                  </div>
                  <p className="text-gray-600 text-xs mt-1">{item.content}</p>
                </div>
              </div>
            ))}
          <div className="px-3 py-1 mt-12 border-t border-gray-200">
            <p className="text-gray-500 text-sm font-semibold text-center hover:text-blue-500 p-3 cursor-pointer">
              View all notifications
            </p>
          </div>
        </div>
      </div>
    </Dropdown>
  );
}

export default Notifications;
