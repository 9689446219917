import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNamespaces, deleteNamespaces } from 'services/api/namespaceService';
import { formatDateTime } from 'utilities/formatDateTime';
import Loading from 'views/loading/Loading';
import { toast } from 'react-toastify';

const NamespacesListInTenant = ({ tenantId, organizationId }) => {
  const [namespaces, setNamespaces] = useState([]);
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNamespaces = async () => {
      try {
        const namespacesData = await getNamespaces(organizationId, tenantId);
        setNamespaces(namespacesData);
      } catch (err) {
        setError('Error fetching namespaces');
        console.error('Error fetching namespaces:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchNamespaces();
  }, [organizationId, tenantId]);

  useEffect(() => {
    if (namespaces.length > 0) {
      setSelectedNamespace(namespaces[0].identity);
    }
  }, [namespaces]);

  const handleDelete = async namespaceIdentity => {
    try {
      await deleteNamespaces(organizationId, tenantId, namespaceIdentity);
      setNamespaces(
        namespaces.filter(
          namespace => namespace.identity !== namespaceIdentity,
        ),
      );
      setSelectedNamespace('');
      toast.success('Namespace deleted successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (err) {
      setError('Error deleting namespace');
      toast.error('Error deleting namespace.', {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error('Error deleting namespace:', err);
    }
  };

  const handleNamespaceSelect = event => {
    setSelectedNamespace(event.target.value);
  };

  if (loading) return <Loading />;
  if (error)
    return <p className="text-red-600 text-center mb-4 mt-6">{error}</p>;
  if (namespaces.length === 0)
    return (
      <p className="text-center text-gray-600 mt-6">No namespaces available.</p>
    );

  const selected = namespaces.find(ns => ns.identity === selectedNamespace);

  return (
    <div className="max-w-8xl mx-auto mt-2">
      {/* Select Dropdown for Namespaces */}
      <div className="mb-4">
        <label htmlFor="namespace-select" className="block text-gray-700">
          Select Namespace:
        </label>
        <select
          id="namespace-select"
          value={selectedNamespace}
          onChange={handleNamespaceSelect}
          className="mt-2 block w-full p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:w-[620px] dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
        >
          {namespaces.map(namespace => (
            <option key={namespace.identity} value={namespace.identity}>
              {namespace.name}
            </option>
          ))}
        </select>
      </div>

      {/* Display Selected Namespace Table only after a namespace is selected */}
      {selectedNamespace && selected && (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-4 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Name
                </th>
                <th className="px-4 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Description
                </th>
                <th className="px-4 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Slug
                </th>
                <th className="px-4 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Created At
                </th>
                <th className="relative px-4 py-1">
                  <span className="text-left text-xs font-medium text-white uppercase tracking-wider">
                    Action
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              <tr className="hover:bg-gray-50 transition dark:hover:bg-navy-600">
                <td className="px-6 py-2 text-sm font-medium text-blue-600 cursor-pointer whitespace-nowrap lg:text-sm dark:text-blue-300">
                  <Link to={'/org/namespaces'}>{selected.name}</Link>
                </td>
                <td className="px-6 py-2 text-sm text-gray-900 whitespace-nowrap lg:text-md dark:text-white">
                  {selected.description}
                </td>
                <td className="px-6 py-2 text-sm text-gray-900 whitespace-nowrap lg:text-md dark:text-white">
                  {selected.slug}
                </td>
                <td className="px-6 py-2 text-sm text-gray-900 whitespace-nowrap lg:text-md dark:text-white">
                  {formatDateTime(selected.createdAt)}
                </td>
                <td className="px-6 py-2 text-center text-sm font-medium">
                  <button
                    onClick={() => handleDelete(selected.identity)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NamespacesListInTenant;
