import apiClient from "../apiService";


export const getClouds = async () => {  
    try {
        const response = await apiClient.get('/v1/clouds');
        return response.data;
    } catch (err) {
        console.error('Error getting clouds:', err);
        return null;
    }
}

export const createCloud = async (cloud) => {
    try {
        const response = await apiClient.post('/v1/clouds', cloud);
        return response;
    } catch (err) {
        console.error('Error creating cloud:', err.response ? err.response.data : err.message);
        throw err;  
    }
};



export const createRegionForCloud = async (cloudId, region) => {
    try {
        const response = await apiClient.post(`/v1/clouds/${cloudId}/regions`, region);
        return response;
    } catch (err) {
        console.error(`Error creating region for cloud ${cloudId}:`, err.response ? err.response.data : err.message);
        console.error('Error response:', err.response);
        return null; 
    }
}