import React, { useEffect, useState } from 'react';
import { getServices } from 'services/api/resourcesService';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const Services = ({ organizationId, tenantId, namespaceId }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        setLoading(true);
        const resourceData = await getServices(
          organizationId,
          tenantId,
          namespaceId,
        );

        if (resourceData.length === 1 && resourceData[0]?.message) {
          setServices([]);
        } else {
          setServices(resourceData);
        }
      } catch (err) {
        console.error('Failed to fetch Services:', err);
        toast.error('Failed to fetch Services.', {
          autoClose: 3000,
          position: 'top-center',
        });
      } finally {
        setLoading(false);
      }
    };

    if (namespaceId) {
      fetchServices();
    }
  }, [organizationId, tenantId, namespaceId]);

  if (loading) return <Loading />;

  return (
    <div>
      <h4 className="text-sm font-semibold mt-8 mb-4 lg:text-lg  dark:text-white">
        Services
      </h4>
      {services.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 table-auto dark:divide-gray-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Service Name
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Cluster IP
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  External IP
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Port(s)
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              {services.map((service, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                >
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {service.name}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {service.cluster_ip}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {service.external_ip || 'None'}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {service['port(s)']}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-gray-500 dark:text-gray-400">
          No services available.
        </div>
      )}
    </div>
  );
};

export default Services;
