import apiClient from "../apiService";


// export const addMembers = async (slug, memberData) => {
//     try {
//         const response = await apiClient.post(`/organizations/${slug}/members`, memberData);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };


export const getUserInfo = async (organizationId) => {
    try {
        const response = await apiClient.get('/v1/memberships', {
            headers: {
                'X-Organisation-Identity': organizationId,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const removeMember = async (slug, membershipIdentity) => {
    if (!membershipIdentity) {
        throw new Error('No membership identity provided, cannot remove member.');
    }

    try {
        const response = await apiClient.delete(`/v1/memberships/${membershipIdentity}`, {
            headers: {
                'X-Organisation-Identity': slug,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
