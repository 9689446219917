import React from 'react';
import { Link } from 'react-router-dom';
import KubeImg from 'assets/img/kube.jpeg';
import WelcomeSection from './WelcomeSection';
import { MdArrowForward } from 'react-icons/md';

const WelcomeModal = ({ onClose }) => {
  const handleOverlayClick = e => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50 hidden md:flex"
      onClick={handleOverlayClick}
    >
      <div
        className="relative bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-blue-500 via-gray-300 to-blue-700 rounded-lg shadow-lg p-24 w-full max-w-3xl mx-2 hidden md:block"
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-4 text-gray-700 hover:text-gray-900 text-2xl font-bold dark:text-gray-900"
        >
          &times;
        </button>

        <div className="flex flex-row justify-center items-center mb-16">
          <h1 className="text-3xl font-semibold text-gray-800 dark:text-navy-700">
            Hello, welcome to{' '}
            <span className="text-customBlue">Kubespaces.io</span>
          </h1>
          <img
            src={KubeImg}
            width={20}
            height={20}
            alt="kube"
            className="ml-2 rounded-full"
          />
        </div>

        <p className="text-sm text-center text-gray-800 mt-4 dark:text-gray-900 mb-6">
          Start your journey with us by setting up your organization and
          resources.
        </p>

        <WelcomeSection />

        <Link to={'/'}>
          <button
            onClick={onClose}
            className="flex justify-center items-center text-sm mt-12 bg-gray-900 text-white px-4 py-2 rounded hover:bg-blue-700 mx-auto"
          >
            Start Your Journey
            <MdArrowForward className="ml-2 h-4 w-4" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeModal;
