import React from 'react';
import { Link } from 'react-router-dom';
import { MdBackup, MdSpaceDashboard, MdApartment } from 'react-icons/md';

const WelcomeSection = () => {
  const sections = [
    {
      title: 'Organization',
      description: 'Set up your first organization to start collaborating.',
      link: '/org/organization',
      icon: <MdApartment className="h-5 w-5 text-blue-500 mr-2" />,
    },
    {
      title: 'Tenant',
      description: 'Manage and create tenants within your organization.',
      link: '/org/tenants',
      icon: <MdBackup className="h-5 w-5 text-blue-500 mr-2" />,
    },
    {
      title: 'Namespace',
      description: 'Organize and separate your resources with namespaces.',
      link: '/org/namespaces',
      icon: <MdSpaceDashboard className="h-5 w-5 text-blue-500 mr-2" />,
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {sections.map((section, index) => (
        <div
          key={index}
          className="bg-white dark:bg-navy-800 shadow-md border border-blue-400 rounded-lg p-6"
        >
          <div className="flex items-center mb-4">
            {section.icon}
            <h3 className="text-sm font-bold text-gray-800 dark:text-white">
              {section.title}
            </h3>
          </div>
          <p className="text-xs text-gray-600 dark:text-gray-300 mt-2">
            {section.description}
          </p>
          <Link to={section.link} className="inline-block mt-4">
            <button className="bg-customBlue text-xs text-white px-2 py-1 rounded hover:bg-gray-800">
              Go to {section.title}
            </button>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default WelcomeSection;
