import apiClient from "../apiService";


export const createTeam = async (team, organisationId) => {
    try {
        const response = await apiClient.post('/v1/teams', team, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error creating team:', err.response ? err.response.data : err.message);
        throw err;
    }
}


export const getTeams = async (organisationId) => {
    try {
        const response = await apiClient.get('/v1/teams', {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting teams:', err);
        return null;
    }
}


export const getTeam = async (teamId, organisationId) => {
    try {
        const response = await apiClient.get(`/v1/teams/${teamId}`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error getting team:', err);
        return null;
    }
}

export const updateTeam = async (team, teamId, organisationId) => {
    try {
        const response = await apiClient.put(`/v1/teams/${teamId}`, team, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error updating team:', err.response ? err.response.data : err.message);
        throw err;
    }
}


export const deleteTeam = async (teamId, organisationId) => {
    try {
        const response = await apiClient.delete(`/v1/teams/${teamId}`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error deleting team:', err.response ? err.response.data : err.message);
        throw err;
    }
};

export const addMemberToTeam = async (organisationId, teamId, userSubject) => {

    try {
        const response = await apiClient.post(`/v1/teams/${teamId}/members`, {
            userIdentity: userSubject, 
        }, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error adding member to team:', error.response ? error.response.data : error.message);
        throw error;
    }
};





export const deleteMemberFromTeam = async (teamId, memberId, organisationId) => {
    try {
      const response = await apiClient.delete(`/v1/teams/${teamId}/members/${memberId}`, {
        headers: {
          'X-Organisation-Identity': organisationId,
        },
      });
      return response;
    } catch (err) {
      console.error('Error deleting member:', err.response ? err.response.data : err.message);
      throw err;
    }
  };
