// RoleBadge.js
import React from 'react';

const MemberRoleBadge = ({ role, size = 'small' }) => {
  const getRoleDisplay = (role) => {
    role = role.toLowerCase();
    switch (role) {
      case 'owner':
        return 'Team Owner';
      case 'member':
        return 'Team Member';
      case 'viewer':
        return 'Team Viewer';
      default:
        return 'Unknown Role';
    }
  };

  const getRoleClass = (role) => {
    role = role.toLowerCase();
    switch (role) {
      case 'owner':
        return 'bg-blue-400 text-white dark:bg-blue-700';
      case 'member':
        return 'bg-green-400 text-white dark:bg-green-700';
      case 'viewer':
        return 'bg-yellow-400 text-white dark:bg-yellow-700';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  const getSizeClasses = () => {
    switch (size) {
      case 'lg':
        return 'p-2 text-[10px]';
      case 'sm':
      default:
        return 'p-1 text-[8px]'; 
    }
  };

  return (
    <span className={`font-semibold  rounded-lg ${getRoleClass(role)} ${getSizeClasses()}`}>
      {getRoleDisplay(role)}
    </span>
  );
};

export default MemberRoleBadge;
