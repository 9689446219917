import React, { useEffect, useState } from 'react';
import { getDeployments } from 'services/api/resourcesService';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const Deployments = ({ organizationId, tenantId, namespaceId }) => {
  const [deployments, setDeployments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDeployments = async () => {
      try {
        setLoading(true);
        const resourceData = await getDeployments(
          organizationId,
          tenantId,
          namespaceId,
        );
        setDeployments(resourceData);
      } catch (err) {
        console.error('Failed to fetch Deployments:', err);
        toast.error('Failed to fetch Deployments.', {
          autoClose: 3000,
          position: 'top-center',
        });
      } finally {
        setLoading(false);
      }
    };

    if (namespaceId) {
      fetchDeployments();
    }
  }, [organizationId, tenantId, namespaceId]);

  if (loading) return <Loading />;

  return (
    <div>
      <h4 className="text-sm font-semibold mt-8 mb-4 lg:text-lg  dark:text-white">
        Deployments
      </h4>
      {deployments && deployments.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 table-auto dark:divide-gray-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Deployment Name
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Age
                </th>
                <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Ready
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              {deployments.map((deployment, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                >
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-blue-300">
                    {deployment.name}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {deployment.status}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {deployment.age}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {deployment.ready}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-gray-500">No deployments available.</div>
      )}
    </div>
  );
};

export default Deployments;
