import React, { useState } from 'react';
import { toast } from 'react-toastify';
import yaml from 'js-yaml';
import { sendYaml } from 'services/api/namespaceService';

const nginxYaml = `
apiVersion: apps/v1
kind: Deployment
metadata:
  name: nginx-deployment
  namespace: default
spec:
  replicas: 1
  selector:
    matchLabels:
      app: nginx
  template:
    metadata:
      labels:
        app: nginx
    spec:
      containers:
        - name: nginx
          image: nginx:1.14.2
          ports:
            - containerPort: 80
`;

const SendYaml = ({ organizationId, tenantId, namespaceId }) => {
  const [yamlContent, setYamlContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const convertYamlToJson = yamlContent => {
    try {
      return yaml.loadAll(yamlContent);
    } catch (e) {
      throw new Error('Invalid YAML format');
    }
  };

  const handleYamlChange = event => {
    setYamlContent(event.target.value);
  };

  const handleNginxClick = () => {
    setYamlContent(nginxYaml);
  };

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      try {
        const text = await file.text();
        setYamlContent(text);
      } catch (err) {
        console.error('Failed to read file:', err);
        toast.error('Failed to read file. Please try again.', {
          autoClose: 3000,
          position: 'top-center',
        });
      }
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const documents = convertYamlToJson(yamlContent);

      for (const doc of documents) {
        if (doc) {
          await sendYaml(organizationId, tenantId, namespaceId, doc);
          toast.success('Document sent successfully!', {
            autoClose: 3000,
            position: 'top-center',
          });
        }
      }

      toast.success('All YAML documents sent successfully!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setYamlContent('');
      setFileName('');
    } catch (err) {
      const errorMessage =
        err.message || 'Failed to send YAML. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage, {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 dark:bg-navy-700 dark:text-white">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="yamlInput"
            className="block text-sm font-semibold mb-2 text-gray-700 dark:text-white"
          >
            Paste your YAML content here or upload a YAML file
          </label>
          <textarea
            id="yamlInput"
            rows="10"
            value={yamlContent}
            onChange={handleYamlChange}
            className="w-full p-3 border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white dark:border-gray-700"
            placeholder="Enter YAML content here..."
            style={{ resize: 'none' }}
            required
          />
        </div>

        {/* File Upload Section */}
        <div className="mb-4">
          <label
            htmlFor="fileInput"
            className="block text-sm font-semibold mb-2 text-gray-700 dark:text-white"
          >
            Or upload a YAML file
          </label>
          <input
            type="file"
            id="fileInput"
            accept=".yaml,.yml"
            onChange={handleFileUpload}
            className="hidden"
          />
          <button
            type="button"
            onClick={() => document.getElementById('fileInput').click()}
            className="bg-customBlue text-white text-xs font-semibold hover:bg-gray-800 py-2 px-4 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700"
          >
            Choose File
          </button>
          {fileName && (
            <span className="ml-2 text-sm text-gray-700 dark:text-white">
              {fileName}
            </span>
          )}
        </div>

        {error && <p className="text-red-600 mb-4">{error}</p>}

        <div className="flex justify-between items-center">
          <button
            type="button"
            onClick={handleNginxClick}
            className="bg-white hover:bg-gray-800 text-green-600 text-xs border border-gray-300 font-semibold py-2 px-4 shadow-md rounded-md dark:bg-navy-600 dark:hover:bg-navy-700 dark:border-gray-900"
          >
            Use NGINX
          </button>

          <button
            type="submit"
            disabled={loading}
            className={`bg-customBlue hover:bg-gray-800 text-white text-xs font-semibold py-2 px-4 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Applying...' : 'Apply'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendYaml;
