import apiClient from "../apiService";



export const createNamespace = async (tenantId, organisationId, namespace) => {
    try {
        const response = await apiClient.post(`/v1/tenants/${tenantId}/namespaces`, namespace, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error creating namespace:', err.response ? err.response.data : err.message);
        throw err;
    }
}

export const getNamespace = async (organisationId, tenantId, namespace_identity) => {
  try {
    const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces/${namespace_identity}`, {
      headers: {
        'X-Organisation-Identity': organisationId,
      },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 404) {
     return null;
      
    } else {
      console.error('Error getting namespace:', err);
      throw err;
    }
  }
};

export const getNamespaces = async (organisationId, tenantId) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}/namespaces`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 404) {
            console.error('Namespaces not found');
            return null;
        } else {
            console.error('Error fetching namespaces:', err.response ? err.response.data : err.message);
            throw err; 
        }
    }
};

export const deleteNamespaces = async (organisationId, tenantId, namespaceIdentity) => {
    try {
        await apiClient.delete(`/v1/tenants/${tenantId}/namespaces/${namespaceIdentity}`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
    } catch (err) {
        console.error('Error deleting namespace:', err);
        throw err;
    }
};

export const UpdateNamespaces = async (organisationId, tenantId, namespaceIdentity, namespaceData) => {
    try {
        await apiClient.put(`/v1/tenants/${tenantId}/namespaces/${namespaceIdentity}`, namespaceData ,{
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
    } catch (err) {
        console.error('Error deleting namespace:', err);
        throw err;
    }
};


export const sendYaml = async ( organisationId, tenantId, namespace_identity, yaml) => {
    try {
        const response = await apiClient.post(`/v1/tenants/${tenantId}/namespaces/${namespace_identity}/deployments`, yaml, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            throw new Error(`Failed to send YAML: ${response.statusText}`);
        }    } catch (err) {
        console.error('Error sending yaml:', err);
        return null;
    }
}

