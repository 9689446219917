import React, { useEffect, useState } from 'react';
import { acceptInvite, declineInvite } from 'services/api/inviteService';
import { getOrganisationInvites } from 'services/api/organizationService';
import { formatDateTime } from 'utilities/formatDateTime';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';
import KeycloakService from 'services/KeycloakService';

const AcceptInvite = ({ inviteCode, onInviteAccepted, onInviteRejected }) => {
  const [invite, setInvite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const userEmail = KeycloakService.getUsername();

  useEffect(() => {
    const fetchInvite = async () => {
      try {
        if (!inviteCode) {
          setError('Invite code is missing.');
          setLoading(false);
          return;
        }
        const invites = await getOrganisationInvites();

        const inviteDetails = invites.find(
          inv => inv.inviteCode === inviteCode,
        );
        if (inviteDetails) {
          setInvite(inviteDetails);
        } else {
          setError('Invite not found.');
        }
      } catch (error) {
        setError('Failed to load invite details.');
        console.error('Error fetching invite:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvite();
  }, [inviteCode]);

  const handleAcceptInvite = async () => {
    try {
      if (!inviteCode) {
        setError('Invite code is missing.');
        return;
      }

      if (!invite) {
        setError('Invite details are missing.');
        return;
      }

      if (!userEmail) {
        setError('User email is not available.');
        return;
      }

      await acceptInvite(inviteCode, invite.organisation.identity, userEmail);

      onInviteAccepted();
      toast.success('Invite accepted successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
      window.location.reload();
    } catch (error) {
      setError('Failed to accept invite.');
      console.error('Error accepting invite:', error);
    }
  };

  const handleRejectInvite = async () => {
    try {
      if (!inviteCode) {
        setError('Invite code is missing.');
        return;
      }

      await declineInvite(
        inviteCode,
        invite.organisation.identity,
        invite.email,
      );
      onInviteRejected();
      toast.success('Invite rejected successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
      window.location.reload();
    } catch (error) {
      setError('Failed to reject invite.');
      console.error('Error rejecting invite:', error);
    }
  };

  return (
    <div className="p-4 w-full max-w-4xl mx-auto">
      <h1 className="text-sm font-bold mb-4 text-center lg:text-md dark:text-white">
        You are invited to the following organisation
      </h1>
      {loading ? (
        <Loading />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : invite ? (
        <div className="flex flex-col items-center justify-between bg-white shadow-md rounded-lg p-4 border border-gray-200 dark:bg-navy-700 dark:text-white">
          <p className="text-xs mb-2 text-center">
            You have been invited to <strong>{invite.organisation.name}</strong>{' '}
            at <strong>{formatDateTime(invite.createdAt)}</strong> with the role{' '}
            <strong>{invite.role}</strong>
          </p>
          <div className="flex flex-row gap-4 justify-center items-center">
            <button
              className="flex-grow px-2 py-1 bg-green-500 text-[10px] text-white rounded hover:bg-green-600 hover:shadow-md lg:text-xs lg:px-4 lg:py-2"
              onClick={handleAcceptInvite}
            >
              Accept Invite
            </button>
            <button
              className="flex-grow px-2 py-1 bg-red-500 text-[10px] text-white rounded hover:bg-red-600 hover:shadow-md lg:text-xs lg:px-4 lg:py-2"
              onClick={handleRejectInvite}
            >
              Reject Invite
            </button>
          </div>
        </div>
      ) : (
        <p className="text-center">No invite details available.</p>
      )}
    </div>
  );
};

export default AcceptInvite;
