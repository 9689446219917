import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from 'assets/img/error-404.png';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-6 py-12">
      <div className="text-center">
        <h1 className="text-6xl font-extrabold text-gray-800 mb-4">404</h1>
        <h2 className="text-2xl md:text-4xl font-semibold text-gray-600 mb-8">
          Page Not Found
        </h2>
        <p className="text-gray-500 mb-8">
          The page you are looking for does not exist or has been moved.
        </p>
        <Link
          to="/org/main-dashboard"
          className="inline-block px-8 py-3 text-white bg-blue-600 rounded-lg shadow-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
        >
          Go Back Home
        </Link>
      </div>
      <div className="mt-12">
        <img
          src={NotFoundImage}
          alt="404 Illustration"
          className="w-30 h-auto"
        />
      </div>
    </div>
  );
};

export default NotFound;
