import React, { useState, useEffect } from 'react';
import { getPodYaml, getNamespaceYaml } from 'services/api/resourcesService';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';
import { FaCopy, FaDownload } from 'react-icons/fa';

const GetYaml = ({
  organizationId,
  tenantId,
  namespaceId,
  pod,
  resourceType,
  resourceName,
}) => {
  const [namespaceYaml, setNamespaceYaml] = useState('');
  const [podYaml, setPodYaml] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchYaml = async () => {
      try {
        setLoading(true);

        const namespaceData = await getNamespaceYaml(
          organizationId,
          tenantId,
          namespaceId,
        );
        setNamespaceYaml(namespaceData);

        if (pod && resourceType === 'pod') {
          const podData = await getPodYaml(
            organizationId,
            tenantId,
            namespaceId,
            pod,
            resourceName,
          );
          setPodYaml(podData);
        }
      } catch (err) {
        console.error(`Failed to fetch YAML data:`, err);
        toast.error(`Failed to fetch YAML data.`, {
          autoClose: 3000,
          position: 'top-center',
        });
      } finally {
        setLoading(false);
      }
    };

    if (namespaceId) {
      fetchYaml();
    }
  }, [organizationId, tenantId, namespaceId, pod, resourceType, resourceName]);

  const copyToClipboard = yaml => {
    navigator.clipboard.writeText(yaml).then(() => {
      toast.success('YAML copied to clipboard!', {
        autoClose: 2000,
        position: 'top-center',
      });
    });
  };

  const downloadYaml = (yaml, filename) => {
    const blob = new Blob([yaml], { type: 'text/yaml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  if (loading) return <Loading />;

  return (
    <div className=" mt-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Namespace YAML Section */}
        <div className="relative bg-white dark:bg-navy-700 p-4 rounded-lg shadow-lg">
          <h5 className="text-xs font-bold text-customBlue lg:text-md dark:text-blue-300 mb-3">
            Namespace YAML
          </h5>

          {/* Always visible buttons */}
          <div className="absolute top-2 right-2 flex space-x-2">
            <button
              className="p-2 text-xs bg-customBlue text-white rounded-md lg:text-md hover:bg-gray-800"
              onClick={() => copyToClipboard(namespaceYaml)}
            >
              <FaCopy />
            </button>
            <button
              className="p-2 text-xs bg-green-700 text-white rounded-md lg:text-md hover:bg-gray-800 dark:bg-green-800 dark:hover:bg-navy-800"
              onClick={() => downloadYaml(namespaceYaml, 'namespace.yaml')}
            >
              <FaDownload />
            </button>
          </div>
        </div>

        {/* Pod YAML Section */}
        {podYaml && (
          <div className="relative bg-white dark:bg-navy-700 p-4 rounded-lg shadow-lg">
            <h5 className="text-xs font-bold text-customBlue lg:text-md dark:text-blue-300 mb-3">
              Pod YAML
            </h5>

            {/* Always visible buttons */}
            <div className="absolute top-2 right-2 flex space-x-2">
              <button
                className="p-2 text-xs bg-customBlue text-white rounded-md lg:text-md hover:bg-gray-800"
                onClick={() => copyToClipboard(podYaml)}
              >
                <FaCopy />
              </button>
              <button
                className="p-2 text-xs bg-green-700 text-white rounded-md hover:bg-gray-800 lg:text-md dark:bg-green-800 dark:hover:bg-navy-800"
                onClick={() => downloadYaml(podYaml, 'pod.yaml')}
              >
                <FaDownload />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetYaml;
